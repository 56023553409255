// ////////////////////////////////////////////////////////////////////////////
// //
// // Copyright 2023 Realm Inc.
// //
// // Licensed under the Apache License, Version 2.0 (the "License");
// // you may not use this file except in compliance with the License.
// // You may obtain a copy of the License at
// //
// // http://www.apache.org/licenses/LICENSE-2.0
// //
// // Unless required by applicable law or agreed to in writing, software
// // distributed under the License is distributed on an "AS IS" BASIS,
// // WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// // See the License for the specific language governing permissions and
// // limitations under the License.
// //
// ////////////////////////////////////////////////////////////////////////////

// import { useCallback, useEffect, useState } from 'react';
// import { useQuery, useRealm, useUser } from '@realm/react';

// import { Task } from '../models/Task';

// /**
//  * Provides functions for managing changes to the tasks in the Realm,
//  * such as adding, updating, and deleting tasks.
//  */
// export function useTaskManager() {
//   const realm = useRealm();
//   const user = useUser();
//   const [requeryFlag, setRequeryFlag] = useState(false); // Temporary flag
//   const tasks = useQuery(Task, (collection) => collection, [requeryFlag]);

//   useEffect(() => {
//     // Temporary solution for making `useQuery` update the `tasks` reference.
//     // (The value doesn't matter, only that it is different from the initial value.)
//     setRequeryFlag(true);
//   }, []);

//   /**
//    * Adds a task to the database.
//    *
//    * @note
//    * Everything in the function passed to `realm.write()` is a transaction and will
//    * hence succeed or fail together. A transaction is the smallest unit of transfer
//    * in Realm so we want to be mindful of how much we put into one single transaction
//    * and split them up if appropriate (more commonly seen server side). Since clients
//    * may occasionally be online during short time spans we want to increase the probability
//    * of sync participants to successfully sync everything in the transaction, otherwise
//    * no changes propagate and the transaction needs to start over when connectivity allows.
//    */
//   const addTask = useCallback((description: string) => {
//     realm.write(() => {
//       realm.create(Task, { description, userId: user.id });
//     });
//   }, [realm, user.id]);

//   /**
//    * Updates a task by toggling its `isComplete` status.
//    *
//    * @note
//    * Normally when updating a record in a NoSQL or SQL database, we have to type
//    * a statement that will later be interpreted and used as instructions for how
//    * to update the record. But in Realm, the objects are "live" because they are
//    * actually referencing the object's location in memory on the device (memory mapping).
//    * So rather than typing a statement, we modify the object directly by changing
//    * the property values. If the changes adhere to the schema, Realm will accept
//    * this new version of the object and wherever this object is being referenced
//    * locally will also see the changes "live".
//    */
//   const toggleTaskStatus = useCallback((task: Task) => {
//     realm.write(() => {
//       task.isComplete = !task.isComplete;
//     });
//   }, [realm]);

//   /**
//    * Deletes a task from the database.
//    */
//   const deleteTask = useCallback((task: Task) => {
//     realm.write(() => {
//       realm.delete(task);
//     });
//   }, [realm]);

//   return {
//     tasks,
//     addTask,
//     toggleTaskStatus,
//     deleteTask,
//   };
// }
// 导入React的钩子函数以及Realm的钩子。
import { useCallback, useEffect, useState } from 'react';
import { useQuery, useRealm, useUser } from '@realm/react';

// 导入Task模型。
import { LinkText } from '../models/Task';
import { v4 as uuidv4 } from 'uuid';
import { json } from 'stream/consumers';
import { Results } from 'realm';

/**
 * useTaskManager是一个自定义钩子，提供对Realm数据库中任务的管理功能，
 * 包括添加、更新和删除任务。
 */
export function useTaskManager(id:String = "") {
  const realm = useRealm(); // 获取当前Realm的实例。
  const user = useUser(); // 获取当前登录的用户。
  
  const [requeryFlag, setRequeryFlag] = useState(false); // 用于触发重新查询的临时标志。

  // 使用useQuery钩子订阅Task模型的数据，useQuery返回一个实时更新的任务集合。
  let stickers = useQuery(LinkText, (collection) => collection, [requeryFlag]).filter(function(currentElement) {
    // let data = JSON.parse(currentElement.data)
    //和当前的URL所匹配的文本

    return id.toLowerCase() == currentElement._id
    // return true
  });
  // const stickers = useQuery(StickerData, (collection) => collection, [requeryFlag]).filter(function(currentElement) {
  //   let data = JSON.parse(currentElement.data)
  //   return data.userID == user.id
  // });;

  useEffect(() => {
    // 当组件加载时，更改requeryFlag的状态来确保useQuery可以获得最新的数据。
    // 这是一个暂时的解决方案，用于使useQuery更新tasks引用。
    setRequeryFlag(true);
  }, []);

  // // 添加任务到数据库的函数。j
  const addSticker = useCallback((url: string): boolean => {
    console.log('create linkText')
    try {
      realm.write(() => {
        // 创建一个新的Task对象，并设置_id和uuid属性。
        realm.create(LinkText, {
          _id: url,
          content: "",
        });
      });
      console.log('LinkText created successfully');
      return true
    } catch (error) {
      console.error('Error creating LinkText:', error);
      alert("Sorry, LinkText.online/" + url + " is taken. Try Again");
      return false
    }
    
  }, [realm, user.id]); // 依赖于realm实例和当前用户的ID。

  // 切换任务完成状态的函数。
  // const toggleTaskStatus = useCallback((task: Task) => {
  //   realm.write(() => {
  //     // 直接在Task对象上修改isComplete属性来更新任务状态。
  //     task.isComplete = !task.isComplete;
  //   });
  // }, [realm]); // 依赖于realm实例。

  const updateLinkTextContent = useCallback((linkText: LinkText, content: string) => {
    realm.write(() => {
      //更新linkText 内容
      linkText.content = content
    });
  }, [realm]); // 依赖于realm实例。

  // // 删除数据库中的任务的函数。
  // const deleteSticker = useCallback((sticker: StickerData) => {
  //   realm.write(() => {
  //     // 删除传入的Task对象。
  //     realm.delete(sticker);
  //   });
  // }, [realm]); // 依赖于realm实例。

  // 返回任务数据和管理任务的函数。
  return {
    stickers,
    updateLinkTextContent,
    addSticker,
  };
}
