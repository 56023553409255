// 导入了用于添加任务的表单组件。
import { AddTaskForm } from '../components/AddTaskForm';
// 导入了用于显示引导文本的组件。
import { IntroText } from '../components/IntroText';
// 导入了用于显示任务列表的组件。
import { TaskList } from '../components/TaskList';
// 导入了一个自定义钩子，用于管理任务的状态和行为。
import { useTaskManager } from '../hooks/useTaskManager';
// 导入了CSS模块样式，用于样式化TaskPage组件。
import styles from '../styles/TaskPage.module.css';
import { RouterProvider, createBrowserRouter, useParams } from 'react-router-dom';

/**
 * TaskPage组件用于显示任务列表以及提供与同步相关的操作。
 * 它利用useTaskManager钩子来获取和管理任务数据。
 */
export function TaskPage() {
  const { routeParam } = useParams();

  // 使用自定义钩子useTaskManager来获取任务数据和相关操作函数。
  const {
    stickers, // 任务列表数组
    // addSticker, // 函数，用于添加新任务
    updateLinkTextContent, // 函数，用于切换任务的完成状态
    // deleteSticker, // 函数，用于删除任务
  } = useTaskManager(routeParam);

  // 组件返回一个div元素，该元素使用styles.container样式。
  // 这个div内部包含AddTaskForm组件用于提交新任务，
  // 并根据条件渲染IntroText组件或TaskList组件。
  return (
    <div className={styles.container}>
      
      {/* 条件渲染：如果没有任务，则显示IntroText组件；
          如果有任务，则显示TaskList组件，并传递任务数据及操作函数作为属性。 */}
      {stickers.length === 0 ? (
        <IntroText /> // 当没有任务时，渲染显示引导文本的IntroText组件。
      ) : (
        <TaskList // 有任务时，渲染TaskList组件，传递任务列表及操作函数。
          stickers={stickers}
          onUpdateSticker={updateLinkTextContent}
          // onDeleteTask={deleteSticker}
        />
      )}
      {/* AddTaskForm组件接收一个onSubmit属性，当表单提交时会调用addTask函数 */}
      {/* <AddTaskForm onSubmit={addSticker} /> */}
    </div>
  );
}
