
// 引入React Router的Navigate和Outlet组件。
// Navigate用于重定向，而Outlet用于渲染当前路由的子组件。
import { RouterProvider, createBrowserRouter, useParams } from 'react-router-dom';

import { Navigate, Outlet } from 'react-router-dom';
// 引入Realm的Provider组件，这些组件用于实现认证和数据管理。
import { RealmProvider, UserProvider } from '@realm/react';

// 引入Task模型，这是定义数据结构的地方，用于Realm数据库。
import { LinkText } from './models/Task';
// 引入PageLayout组件，这是应用程序页面布局的组件。
import { PageLayout } from './components/PageLayout';
import { AuthOperationName, useApp, useEmailPasswordAuth, useAuth } from '@realm/react';
import { FormEvent, useEffect, useState } from 'react';
import { LoginPage } from './pages/LoginPage';
import PoweredByOneSticker from './components/PoweredByOneSticker';
/**
 * AuthenticatedApp组件是应用程序中已认证用户能访问的部分。
 * 它使用了Realm的UserProvider和RealmProvider来提供认证后的用户信息
 * 和打开一个Realm数据库的能力。
 */
export function LoginApp() {
    // const {logInWithAnonymous, result} = useAuth();
    const {logInWithAnonymous, result} = useAuth();

    const { routeParam } = useParams();
    console.log("Dynamic route triggered " + routeParam)
    //在这里做App匿名登录

    // 自动登录功能
    // 延时1秒后自动登录
    useEffect(() => {
        logInWithAnonymous()
    }, []); // 依赖数组为空，确保只执行一次
    
  return (
    // 使用UserProvider来检查用户是否已经认证。fallback属性提供一个未认证时渲染的组件。
    // 如果用户未认证，我们使用Navigate组件将用户重定向到登录页面。
    // <UserProvider fallback={<Navigate to='/' />}>

    <UserProvider>
      {/* <div>AuthenticatedApp is active</div> */}
       {/* RealmProvider组件用于打开一个Realm数据库实例。
    //       schema属性定义了数据库中包含的模型，这里只有Task模型。
    //       sync属性配置了数据库的同步选项。*/}
      <RealmProvider
        schema={[LinkText]} // 设置Realm使用的模型，这里只使用Task模型。
        sync={{
          flexible: true, // 开启灵活同步模式。
          // initialSubscriptions定义了初始化订阅，它决定了哪部分数据将被同步到本地数据库。
          initialSubscriptions: {
            update: ((mutableSubs, realm) => {
              // 这里订阅了所有Task对象，并给这个订阅命名为'allSticker'。
              mutableSubs.add(realm.objects(LinkText), { name: 'LinkText' });
            }),
          },
        }}
        >
        {/* PageLayout组件定义了页面的布局，Outlet组件则会渲染当前路由的子组件。 */}
            <LoginPage />
            {/* 在LoginPage组件下方添加PoweredByOneSticker组件 */}
            <PoweredByOneSticker />
      </RealmProvider>
    </UserProvider>
  );
}
