////////////////////////////////////////////////////////////////////////////
//
// 版权所有 2023 Realm Inc.
//
// 根据Apache License 2.0版授权许可。
// 除非遵守许可，否则不得使用此文件。
// 可以在下面的链接获取许可证副本：
// http://www.apache.org/licenses/LICENSE-2.0
//
// 除非法律要求或书面同意，否则根据许可证分发的软件
// 是在"按原样"基础上提供的，不带任何种类的保证或条件，无论是明示的还是暗示的。
// 请参阅许可证以了解管理权限和限制。
//
////////////////////////////////////////////////////////////////////////////
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
// 导入Task类型，这通常是在你的模型文件中定义的TypeScript类型或接口。
import { json } from 'stream/consumers';
import type { LinkText } from '../models/Task';
// 导入TaskItem组件的样式模块。
import styles from '../styles/TaskItem.module.css';
import debounce from 'lodash/debounce';

// 定义TaskItemProps类型，这是一个TypeScript类型定义，规范了TaskItem组件的props。
type TaskItemProps = {
  linkText: LinkText; // Task对象，包含了任务相关的数据。
  onUpdateSticker: (linkText: LinkText, content: string) => void; // 切换任务完成状态的函数。
};

/**
 * 渲染单个任务项并提供更新或删除操作的组件。
 * @param {TaskItemProps} { task, onToggleStatus, onDelete } - 解构赋值，直接从props中提取对应的属性。
 * @return 返回表示任务项的React元素，包含描述和按钮。
 */

export function TaskItem({linkText, onUpdateSticker }: TaskItemProps) {
  const { t } = useTranslation();
  const [stickerContent, setStickerContent] = useState<string>(linkText.content);

  const debouncedUpdate = useCallback(
    debounce((text: string) => {
      onUpdateSticker(linkText, text);
    }, 1000),
    [linkText, onUpdateSticker]
  );

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const currentText = event.target.value;
    setStickerContent(currentText);
    debouncedUpdate(currentText);
    console.log('UpdateSticker ', currentText);
    console.log('linkText ', linkText._id, linkText.content);
  };

  return (
    <div className={styles.task} style={{backgroundColor: '#F2EFAF'}}>
      <textarea 
        value={stickerContent} 
        onChange={handleChange} 
        placeholder={t('Enter Text Here')}
      />
    </div>
  );
}