import { useApp, useAuth } from '@realm/react';
import { useState, useEffect, useRef } from 'react';
import QRCode from 'qrcode';
import { useTranslation } from 'react-i18next';
import styles from '../styles/NavBar.module.css';

function copyLink() {
  navigator.clipboard.writeText(window.location.href)
    .then(() => {
      alert('Link copied to clipboard!');
    })
    .catch(err => {
      console.error('Failed to copy link: ', err);
    });
}

function ShowAQRCodeOfThisPage() {
  const { t } = useTranslation();
  const [showQR, setShowQR] = useState(false);
  const qrRef = useRef(null);

  useEffect(() => {
    if (showQR && qrRef.current) {
      QRCode.toCanvas(qrRef.current, window.location.href, function (error) {
        if (error) console.error('Error generating QR code:', error);
      });
    }
  }, [showQR]);

  const toggleQRCode = () => {
    setShowQR(!showQR);
  };

  return (
    <div>
      <button className={styles.button} onClick={toggleQRCode}>
        {t('QR Code')}
      </button>
      {showQR && (
        <div className={styles.qrCodeContainer}>
          <canvas ref={qrRef}></canvas>
        </div>
      )}
    </div>
  );
}

function goToHomePage() {
  window.location.href = '/';
}

export function NavBar() {
  const app = useApp();
  const { t } = useTranslation();
  return (
    <nav className={styles.nav}>
      <button className={styles.button} onClick={goToHomePage}>
        {t('Get New Link')}
      </button>
      <ShowAQRCodeOfThisPage />
      <button className={styles.button} onClick={copyLink}>
        {t('Copy Link')}
      </button>
      <div className={styles.titleContainer}>
        {/* Your existing code */}
      </div>
    </nav>
  );
}