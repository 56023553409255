////////////////////////////////////////////////////////////////////////////
//
// 版权所有 2023 Realm Inc.
//
// 根据Apache License 2.0版授权许可。
// 除非遵守许可，否则不得使用此文件。
// 可以在下面的链接获取许可证副本：
// http://www.apache.org/licenses/LICENSE-2.0
//
// 除非法律要求或书面同意，否则根据许可证分发的软件
// 是在“按原样”基础上提供的，不带任何种类的保证或条件，无论是明示的还是暗示的。
// 请参阅许可证以了解管理权限和限制。
//
////////////////////////////////////////////////////////////////////////////

// 从react库中导入所需的Hooks函数
import { FormEvent, useEffect, useState } from 'react';
// 导入react-router-dom库中的Navigate组件，用于路由跳转
import { useNavigate } from 'react-router-dom';

// 导入@realm/react库中相关的功能，用于处理认证
import { AuthOperationName, useApp, useEmailPasswordAuth, useAuth } from '@realm/react';

// import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useTaskManager } from '../hooks/useTaskManager';

// 导入登录页的logo图片
import logo from '../assets/atlas-app-services.png';
// 导入登录页样式模块
import styles from '../styles/LoginPage.module.css';
import { useTranslation } from 'react-i18next';
// const [linkText, setLinkText] = useState('');

/**
 * 登录和注册App服务应用的页面组件
 */
export function LoginPage() {
  const navigate = useNavigate();


  // 使用useApp Hook获取应用实例
  const atlasApp = useApp();
  // 使用useEmailPasswordAuth Hook来处理注册和登录操作，并获取结果
  const {logInWithAnonymous, result} = useAuth();
  // 使用useState Hook来管理用户是想登录还是注册
  const [authRequest, setAuthRequest] = useState<'login' | 'register'>('login');
  const {
    // stickers, // 任务列表数组
    addSticker, // 函数，用于添加新任务
    // updateLinkTextContent, // 函数，用于切换任务的完成状态
    // deleteSticker, // 函数，用于删除任务
  } = useTaskManager();

  const [linkText, setLinkText] = useState('');

  // 如果当前用户已经登录成功，则使用<Navigate>组件重定向到任务页面
  // if (atlasApp.currentUser) {
  //   return <Navigate to='/tasks' />;
  // }

  // 处理表单提交的函数
  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    // 阻止表单的默认提交行为
    event.preventDefault();
  };

  // 创建URL操作
  const handleButtonClicked = (event: FormEvent<HTMLButtonElement>): void => {
    //Add Sticker url and jump to that url
    
    // addSticker()
    if (linkText.trim()) {
      //需要检查一下是否已被定义
      let isAdded = addSticker(linkText.toLowerCase());
      if (isAdded) {
        let url = '/' + linkText
        navigate(url); // 使用 navigate 函数进行跳转
      }
    }
  };



  //给linkText随机生成一个6位的小写字母组合
  const handleRandomClicked = (event: FormEvent<HTMLButtonElement>): void => {
    event.preventDefault(); // 阻止按钮的默认行为
  
    const generateRandomString = (length: number): string => {
      const characters = 'abcdefghijklmnopqrstuvwxyz';
      let result = '';
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return result;
    };
  
    const randomLink = generateRandomString(6);
    setLinkText(randomLink);
  };

  const { t } = useTranslation();
  // 登录页面的JSX结构
  return (
      <div className={styles.container}>
        
        
        <form onSubmit={handleSubmit} className={styles.form}>
        <h2>{t('1. Claim Your Link')}</h2>
        <div className={styles.urlInputDiv}>
        
          <p>https://linktext.online/</p>
          <input
          className={styles.input}
          type='text'
          placeholder={t('your link')}
          value={linkText}
          onChange={(event) => setLinkText(event.target.value)}
          autoCorrect='off'
          autoCapitalize='none'
        />
          <button
              className={styles.button2}
              type='button'
              value='login'
              onClick={handleRandomClicked}
            >
             {t('Random')}
            </button>
          <button
              className={styles.button}
              type='button'
              value='login'
              onClick={handleButtonClicked}
            >
              {t('Claim This link')}
            </button>

        </div>
          <h2>{t('2.Use It as an Online Text File')}</h2>
          <h3>{t('All your text input in textfile will be in public ! please be careful')}</h3>
          {/* {result.error && <p className={styles.error}>{result.error.message}</p>} */}
          <div className={styles.buttons}>
            
          </div>
          {result.error && !result.error.message.includes('App not available in requested region') && <p className={styles.error}>{result.error.message}</p>}   

        </form>
      </div>
  );
}
