import { RouterProvider, createBrowserRouter, useParams } from 'react-router-dom';
import { AppProvider, } from '@realm/react';

import { AuthenticatedApp } from './AuthenticatedApp';
import { LoginApp } from './LoginApp';
import { ErrorPage } from './pages/ErrorPage';
import { LoginPage } from './pages/LoginPage';
import { TaskPage } from './pages/TaskPage';
import config from './atlas-app-services/config.json';
import styles from './styles/App.module.css';
import { AuthOperationName, useApp, useEmailPasswordAuth, useAuth } from '@realm/react';


const router = createBrowserRouter([
  {
    path: '/',
    element: <LoginApp />,
    errorElement: <ErrorPage />,
  },
  {
    element: <AuthenticatedApp />,
    children: [
      {
        path: ':routeParam', // 新增的动态路由
        element: 
        <>
          <TaskPage />
        </>
      }
    ]
  }
]);

function App() {
  return (
    <div className={styles.container}>
      <AppProvider id={config.ATLAS_APP_ID}>
        <RouterProvider router={router} />
      </AppProvider>
    </div>
  );
}

export default App;