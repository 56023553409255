import React from 'react';
import { useTranslation } from 'react-i18next';

const PoweredByOneSticker = () => {
  const handleRedirect = () => {
    window.location.href = 'https://onesticker.app';
  };
  const { t } = useTranslation();
  return (
    <div onClick={handleRedirect} style={{
      cursor: 'pointer', 
      textAlign: 'center', 
      position: 'fixed',
      left: 0,
      bottom: 0,
      width: '100%',
      backgroundColor: 'white',
      padding: '10px 0',
      boxShadow: '0px -2px 5px rgba(0,0,0,0.1)'
    }}>
      {/* 添加图标 */}
      <img src="sticker.jpg" alt="Sticker Icon" style={{
        height: '50px', // 设置图标的高度，你可以根据需要调整
        width: 'auto', // 保持图标的宽高比
        display: 'block', // 使图标和文字垂直排列
        margin: '0 auto 8px', // 居中显示图标，并在其下方添加一些间距
      }}/>
      <div style={{fontWeight: 'bold', fontSize: '18px'}}>{t('Powered By OneSticker.app')}</div>
      <div style={{fontSize: '16px'}}>{t('OneSticker quickly syncs your sticky notes across Mac, Windows, iOS, Android, and even the Web')}</div>
    </div>
  );
};

export default PoweredByOneSticker;
