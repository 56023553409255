import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      zh: {
        translation: {
          "Enter Text Here": "在此输入文本",
          "Get New Link": "获取新链接",
          "Copy Link": "复制链接",
          "QR Code": "二维码",
          "1. Claim Your Link": "1. 生成专属于您的链接",
          "Claim This link": "生成",
          "2.Use It as an Online Text File": "2. 用作在线文本文件",
          "All your text input in textfile will be in public ! please be careful": "文本文件中的所有文本输入都将公开！请谨慎操作。",
          "Random": "随机",
          "your link": "标识符",
          "Powered By OneSticker.app": "Powered By OneSticker.app",
          "OneSticker quickly syncs your sticky notes across Mac, Windows, iOS, Android, and even the Web": "OneSticker 是一个全平台同步便签App"
        }
      }
    },
    fallbackLng: "en",
    detection: {
      order: ['navigator']
    },
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
