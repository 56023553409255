////////////////////////////////////////////////////////////////////////////
//
// 版权所有 2023 Realm Inc.
//
// 根据Apache License 2.0版授权许可。
// 除非遵守许可，否则不得使用此文件。
// 可以在下面的链接获取许可证副本：
// http://www.apache.org/licenses/LICENSE-2.0
//
// 除非法律要求或书面同意，否则根据许可证分发的软件
// 是在“按原样”基础上提供的，不带任何种类的保证或条件，无论是明示的还是暗示的。
// 请参阅许可证以了解管理权限和限制。
//
////////////////////////////////////////////////////////////////////////////

// 引入realm库中的Results类型，这是realm数据库查询返回的结果集类型。
import { Results } from 'realm';

// 引入Task类型定义，这是在你的应用模型层定义的Task数据结构。
import type { LinkText } from '../models/Task';
// 引入TaskItem组件，用于展示单个任务项。
import { TaskItem } from './TaskItem';
// 引入TaskList组件的样式模块。
import styles from '../styles/TaskList.module.css';

// 定义TaskListProps类型，这是一个TypeScript类型定义，规范了TaskList组件的props。
type TaskListProps = {
  stickers: LinkText[]; // 任务的结果集，每个任务都符合Task类型。
  onUpdateSticker: (sticker: LinkText, content: string) => void; // 用于切换任务完成状态的函数。
};

/**
 * 用于展示任务列表的组件。
 * @param {TaskListProps} { tasks, onToggleTaskStatus, onDeleteTask } - 解构赋值，直接从props中提取对应的属性。
 * @return 返回一个div元素，里面包含了多个TaskItem组件来展示每个任务。
 */
export function TaskList({ stickers, onUpdateSticker }: TaskListProps) {
  // 使用div元素包裹任务列表，应用了styles.tasks定义的样式。
  return (
    <div className={styles.tasks}>
      {/* 使用tasks.map迭代每个任务，并为每个任务渲染一个TaskItem组件 */}
      {stickers.map((sticker) => (
        <TaskItem
          key={sticker._id} // key属性是必须的，用于优化React的渲染性能。这里使用任务的_id作为唯一标识。
          linkText={sticker} // 将任务数据传递给TaskItem组件。
          onUpdateSticker={onUpdateSticker} // 将切换任务状态的函数传递给TaskItem组件。
        />
      ))}
    </div>
  );
}
